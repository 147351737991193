export default {
    init() {
      
        $(document).on('click', '.load-more-contributors-btn', (event) => {
            event.preventDefault();
            const button = $(event.currentTarget)
            const container = $('.contributors-list');
            
            button.toggleClass('disabled', true);
            button.text('Loading...');

            container.load( "/contributors #list-content", () => { 
                button.hide() 
                $('.contributors-group').show();
            });

        });
    }
};
  