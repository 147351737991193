import jquery from 'jquery'

// const $ = jquery;

export default {
  init() {
    // JavaScript to be fired on all pages

    // Mini Search
    $(document).on('click', '.search-clear-icon', () => {
      if ($('.search-input').val() === '') {
        this.closeMiniSearch()
      }
      $('.search-input').val('')
    });

    $(document).on('click', '.mini-search-toggle', () => {
      $('.mini-search-form').show()
      $('.mini-search-toggle').hide()
    })

    // Mobile "More" menu - scroll to footer
    jQuery("a[href='#browse']").on('click', function (event) {
      event.preventDefault();
      var w = jQuery('body').width();
      jQuery([document.documentElement, document.body]).animate({
        scrollTop: jQuery("a[name='browse']").offset().top 
      }, 500);
    });

    // Load More Posts
    $(document).on('click', '.load-more-btn', (event) => {
        const button = $(event.currentTarget)
        const container = $('.load-more');
        const data = {
          'action': 'loadmore',
          'query': wp_params.posts, // that's how we get params from wp_localize_script() function
          'page' : wp_params.current_page,
          'loop' : button.data('loop'),
          'wrapper' : button.data('wrapper'),
        };
        

        const orig_button_txt = button.text()

        // console.log('Load More', wp_params, button);
        $.ajax({ // you can also use $.post here
          url : wp_params.ajaxurl, // AJAX handler
          data : data,
          type : 'POST',
          beforeSend : function ( xhr ) {
            button.toggleClass('disabled', true);
            button.text('Loading...'); // change the button text, you can also add a preloader image
          },
          success : function( data ){
            if( data ) { 
              button.toggleClass('disabled', false);
              button.text(orig_button_txt);
              container.append(data); // insert new posts
              wp_params.current_page++;
     
              if ( wp_params.current_page == wp_params.max_page ) 
                button.remove(); // if last page, remove the button
     
              // you can also fire the "post-load" event here if you use a plugin that requires it
              // $( document.body ).trigger( 'post-load' );
              setTimeout(() => {
                $('.load-more-item').addClass('reveal');
              }, 10)
              
            } else {
              button.remove(); // if no data, remove the button as well
            }
          }
        });
    })

    // Open External Links in a new tab
    $('a').each(function() {
      const a = new RegExp('/' + window.location.host + '/');
      if(!a.test(this.href)) {
          $(this).click(function(event) {
              event.preventDefault();
              event.stopPropagation();
              window.open(this.href, '_blank');
          });
      }
    });

    // grayscale avatar images using the Photon (Jetpack) API
    $('img.wp-user-avatar').each(function() {
      const $img = $(this); 
      $img.attr('src', $img.attr('src') + '&filter=grayscale');
    });

    // comment bar
    $(document).on('shown.bs.collapse', '#comments-holder', function () {
      console.log('showing comments');
    })
    
    $(document).on('click', '.share-comment', function (event) {
      event.preventDefault();

      // Open comments holder
      $('#comments-holder').collapse({
        toggle: true,
      });

      // scroll to bottom
      setTimeout(function() {
        $([document.documentElement, document.body]).animate({
          scrollTop: $("#comments-holder").offset().top - 30
        }, 350);
      }, 350);

    })

    $(window).resize(this.onResize);
    this.onResize();   
  },

  onResize() {
    const isIOS = /^iP/.test(navigator.platform);

    // update the 'no-fixed' class every time the window size changes
    $('body').toggleClass('no-fixed', window.innerWidth <= 1024 || isIOS);
  },

  closeMiniSearch() {
    // console.log('close mini search');
    $('.mini-search-form').hide()
    $('.mini-search-toggle').show()
  },

  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
